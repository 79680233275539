import GoogleLogo from '../../assets/images/google-login-logo.png'
import Image from 'next/legacy/image'
import { AtSign } from 'react-feather'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { getAnalytics, logEvent } from 'firebase/analytics'

const API_URL = process.env.NEXT_PUBLIC_API_URL

function LoginSocial({ handleLoginMethod }: any) {
  const { t } = useTranslation('login')

  const logLoginButtonClick = (method: string) => {
    logEvent(getAnalytics(), 'login_button', {
      method,
    })
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body py-4">
              <div className="login-methods mb-3">
                <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
                  <a
                    href={`${API_URL}/auth/google-login`}
                    className="btn btn-outline-primary btn-google-auth"
                    onClick={() => logLoginButtonClick('google')}
                  >
                    <Image
                      src={GoogleLogo}
                      width={18}
                      height={18}
                      layout="fixed"
                      alt={t('login-with-google')}
                    />{' '}
                    {t('login-with-google')}
                  </a>

                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => {
                      logLoginButtonClick('email')
                      handleLoginMethod('email')
                    }}
                  >
                    <AtSign size={18} /> {t('login-with-email')}
                  </button>
                </div>
              </div>
              <div className="text-center">
                {t('dont-have-account')}{' '}
                <Link href="/register" legacyBehavior>
                  {t('register')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginSocial

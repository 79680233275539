import * as React from 'react'
import { useMutation } from 'react-query'
import { loginUser, sendResetPasswordEmail } from '../../services/api'
import { useUser } from '../../context/user'
import { useRouter } from 'next/router'
import Image from 'next/legacy/image'
import GoogleLogo from '../../assets/images/google-login-logo.png'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

const API_URL = process.env.NEXT_PUBLIC_API_URL

function LoginEmail() {
  const [loginData, setLoginData] = React.useState({
    email: '',
    password: '',
  })

  const router = useRouter()
  const { t } = useTranslation('login')
  const { login } = useUser()
  const { mutate, isLoading, isError, isSuccess, error, data } =
    useMutation(loginUser)

  const {
    mutate: mutateResetPassword,
    isError: isErrorResetPassword,
    isSuccess: isSuccessResetPassword,
    error: errorResetPassword,
  } = useMutation(sendResetPasswordEmail)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutate(loginData)
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value })
  }

  const handleResetPassword = async () => {
    mutateResetPassword(loginData.email)
  }

  React.useEffect(() => {
    if (isSuccess) {
      router.push('/login/success/?token=' + data.access_token)
    }
  }, [isSuccess])

  return (
    <div className="container">
      <section className="row flex-column align-items-center gap-3 mb-3">
        <div className="col-md-9 col-lg-6">
          {isError && error instanceof Error && (
            <>
              <div className="alert alert-danger">{error.message}</div>
            </>
          )}
          {isSuccessResetPassword && (
            <div className="alert alert-success">
              {t('send-reset-password-success')}
            </div>
          )}
          {isErrorResetPassword && errorResetPassword instanceof Error && (
            <div className="alert alert-danger">
              {errorResetPassword.message}
            </div>
          )}
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email">{t('email')}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    onChange={handleFieldChange}
                    value={loginData.email}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password">{t('password')}</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    onChange={handleFieldChange}
                    value={loginData.password}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>{' '}
                      {t('loading')}
                    </>
                  ) : (
                    t('login')
                  )}
                </button>
                <button
                  type="button"
                  className="d-block btn btn-link p-0 mt-3"
                  onClick={handleResetPassword}
                >
                  {t('send-reset-password')}
                </button>
              </form>
              <div>
                {t('dont-have-account')}{' '}
                <Link href="/register" legacyBehavior>
                  {t('register')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="row flex-column align-items-center gap-3">
        <div className="col-md-9 col-lg-6">
          <div className="card">
            <div className="card-body">
              <a
                href={`${API_URL}/auth/google-login`}
                className="btn btn-outline-primary btn-google-auth"
              >
                <Image
                  src={GoogleLogo}
                  width={18}
                  height={18}
                  layout="fixed"
                  alt={t('login-with-google')}
                />{' '}
                {t('login-with-google')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LoginEmail

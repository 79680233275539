import * as React from 'react'
import type { NextPage } from 'next'
import Layout from '../../src/components/layout'
import LoginEmail from '../../src/components/login/login-email'
import LoginSocial from '../../src/components/login/login-social'
import PageHead from '../../src/components/layout/page-head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useUser } from '../../src/context/user'
import Custom404 from '../404'

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'header',
      'login',
      'footer',
      '404',
    ])),
  },
})

const Login: NextPage = () => {
  const { t } = useTranslation('login')
  const [loginMethod, setLoginMethod] = React.useState('social')
  const { user } = useUser()

  if (user) {
    return <Custom404 />
  }

  return (
    <Layout>
      <PageHead title={t('login')} />
      {loginMethod === 'email' ? (
        <LoginEmail />
      ) : (
        <LoginSocial handleLoginMethod={setLoginMethod} />
      )}
    </Layout>
  )
}

export default Login
